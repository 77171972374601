
import React from 'react';
import Map from 'devextreme-react/map';
import { IMapProps } from 'BCMap/entities/IMapProps';
import { InitializedEvent } from 'devextreme/ui/map';
import { MLHelper } from '@bimser/core';
import { BCMapRef } from 'BCMap/entities/IMapRef';

const BCMap = React.forwardRef<BCMapRef, IMapProps>((props, ref) => {

    const mapRef = React.useRef<Map>();
    React.useImperativeHandle(ref, () => ({
        ref: mapRef.current,
        addRoute: mapRef.current.instance.addRoute,
        addMarker: mapRef.current.instance.addMarker,
        removeRoute: mapRef.current.instance.removeRoute,
        removeMarker: mapRef.current.instance.removeMarker,
        repaint: mapRef.current.instance.repaint
    }));

    const routesData = () => {
        if (props.routes) {
            let routeDataUpdate = props.routes.map(item => {
                item.mode = props.routeMode || 'driving';

                return item
            })
            return routeDataUpdate
        }
        return []
    }

    const onClickMap = (e) => {
        props.onClick?.();
    }

    const onInitialized = (args: InitializedEvent) => {
        props.events?.onInitialized?.(args);
    }

    const getMarkers = () => props.markers?.map(item => ({
        ...item,
        tooltip: {
            ...item.tooltip,
            text: item.tooltip.text?.[MLHelper.currentLanguage.culture] || ""
        }
    })) || [];

    const apiKey = { [props.provider]: props.apiKey }

    return (
        <Map
            apiKey={apiKey}
            defaultCenter={props.center}
            defaultZoom={props.zoom}
            disabled={props.disabled}
            onInitialized={onInitialized}
            autoAdjust={props.autoAdjust}
            height={props.height}
            width={props.width}
            controls={props.controls}
            provider={props.provider}
            type={props.type}
            markers={getMarkers()}
            routes={routesData()}
            onClick={onClickMap}
            ref={mapRef}
        />

    )
})

export default BCMap