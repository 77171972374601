import 'bootstrap/dist/css/bootstrap-grid.min.css';
import * as React from "react";
import { BCList, BCNotification, findMessage } from "../..";
import * as Styles from '../assets/style.scss';
import { IIconFinderProps } from '../entities';
import GetIcon8ByName from "@bimser/icons/lib/getIcon8ByName";
import GetIcon16ByName from "@bimser/icons/lib/getIcon16ByName";
import GetIcon24ByName from "@bimser/icons/lib/getIcon24ByName";
import GetIcon32ByName from "@bimser/icons/lib/getIcon32ByName";
import GetIcon48ByName from "@bimser/icons/lib/getIcon48ByName";
import GetIcon64ByName from "@bimser/icons/lib/getIcon64ByName";

import { GetIconComponent, IconInfo } from '@bimser/icons';
const _startCase = require("lodash/startCase");
const _toLower = require("lodash/toLower");
var classNames = require('classnames/bind');
let cx = classNames.bind(Styles);

export default class BCIconFinder extends React.Component<IIconFinderProps, {}> {

    pageSizeOptions: string[] = ['20', '30', '40', '50'];

    constructor(props: IIconFinderProps) {
        super(props);
        this.onIconClick = this.onIconClick.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.getIcons = this.getIcons.bind(this);
        this.getIconByName = this.getIconByName.bind(this);
    }

    getIcons(): IconInfo[] {
        let items: IconInfo[] = [];

        let req = this.getRequireContext();
        req.keys().forEach((key: any) => {
            const Icon = req(key).default;
            items.push(Icon.info);
        });

        return items.filter(i => !this.props.searchKey || i.name.toLowerCase().indexOf(this.props.searchKey.toLowerCase()) > -1);
    }

    getRequireContext() {
        // size string geldiği için if ile yapıldı.
        if (this.props.size == 8) {
            return (require as any).context("@bimser/icons/8", true, /\.js$/);
        }
        else if (this.props.size == 16) {
            return (require as any).context("@bimser/icons/16", true, /\.js$/);
        }
        else if (this.props.size == 24) {
            return (require as any).context("@bimser/icons/24", true, /\.js$/);
        }
        else if (this.props.size == 32) {
            return (require as any).context("@bimser/icons/32", true, /\.js$/);
        }
        else if (this.props.size == 48) {
            return (require as any).context("@bimser/icons/48", true, /\.js$/);
        }
        else if (this.props.size == 64) {
            return (require as any).context("@bimser/icons/64", true, /\.js$/);
        }
        else {
            return (require as any).context("@bimser/icons/16", true, /\.js$/);
        }
    }

    getIconByName(iconName: string) {
        switch (this.props.size) {
            case 8:
                return GetIcon8ByName(iconName);
            case 16:
                return GetIcon16ByName(iconName);
            case 24:
                return GetIcon24ByName(iconName);
            case 32:
                return GetIcon32ByName(iconName);
            case 48:
                return GetIcon48ByName(iconName);
            case 64:
                return GetIcon64ByName(iconName);
            default:
                return GetIcon16ByName(iconName);
        }
    }

    onIconClick(e: React.MouseEvent<HTMLDivElement>) {
        let iconName = e.currentTarget.id;
        const iconSize = e.currentTarget.getAttribute("data-size");

        e.stopPropagation();
        if (this.props.copySelectedKey) {
            const programaticIconName = _startCase(_toLower(_startCase(iconName))).split(" ").join("");
            const copyString = `import Icon${programaticIconName} from "@bimser/icons/${iconSize}/${iconName}";`;

            var textField = document.createElement('textarea')
            textField.innerText = copyString
            document.body.appendChild(textField)
            textField.select()
            document.execCommand('copy')
            textField.remove();

            if (this.props.onClick) this.props.onClick(iconName);
            BCNotification.info({
                message: findMessage.get('101524'),
                type: "info",
                duration: 2,
                placement: "bottomRight",
                key: iconName,
                description: iconName + findMessage.get('102004')
            })
        }
        const Icon = this.getIconByName(iconName);
        if (this.props.onChange) {
            this.props.onChange(Icon.info);
        }
    }

    renderItem(iconInfo: IconInfo): React.ReactNode {
        let classNames = cx({
            iconItem: true,
            selected: this.props.selectedKey && this.props.selectedKey.name === iconInfo.name,
        });
        const Icon = GetIconComponent(iconInfo);

        return (
            <div key={this.props.size + "_" + iconInfo.id} data-size={iconInfo.size} id={iconInfo.name} className={classNames} title={iconInfo.name} onClick={this.onIconClick}>
                {Icon}
                <span className={Styles.iconText}>{iconInfo.name}</span>
            </div>
        )

    }

    render() {

        return (
            <BCList
                dataSource={this.getIcons()}
                bordered={false}
                split={false}
                loading={false}
                renderItem={this.renderItem}
                className={Styles.iconFinderContainer}
                pagination={{
                    defaultPageSize: 30,
                    pageSizeOptions: this.pageSizeOptions
                }}
            />
        )

    }

}